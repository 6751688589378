<style>
  .login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    flex-direction: column;
  }

  .login-box {
    display: flex;
    background-color: white;
    min-width: 20em;
    flex-direction: column;
    padding: 2em;
    align-items: center;
  }

  .error-box {
    display: flex;
    background-color: white;
    min-width: 20em;
    flex-direction: column;
    padding: 1em 2em;
    align-items: center;
  }

  .error-wrapper {
    height: 0.7em;
  }

  .error-text {
    display: block;
    color: red;
    font-size: 0.7em;
  }
</style>

<div class="login-container">
  <div class="login-box">
    <Title />
    <GoogleLogin />
  </div>

  {#if $isClassicLoginShown}
  <Separator />
  <div class="login-box">
    <ClassicLogin />
  </div>
  {/if}

  {#if $serverErrorMessage}
  <Separator />
  <div class="error-box">
    <div class="error-wrapper">
      <label class="error-text">{$serverErrorMessage}</label>
    </div>
  </div>
  {/if}
</div>

<script>
  import Title from './components/Title.html'
  import ClassicLogin from './components/ClassicLogin.html'
  import GoogleLogin from './components/GoogleLogin.html'
  import Separator from './components/Separator.html'
  import store from './store.js'

  export default {
    store: () => store,
    components: {
      Title,
      ClassicLogin,
      GoogleLogin,
      Separator
    }
  }
</script>