import { Store } from 'svelte/store'

const store = new Store({
  isClassicLoginShown: false,
  serverErrorMessage: ''
})

export default store

// create old login keybind
document.onkeydown = function (e) {
  if (e.shiftKey && e.altKey && e.ctrlKey && e.which === 79) {
    store.set({ isClassicLoginShown: true })
  }
}

// used for debug
window.svelteStore = store