<style>
  .g-signin2 {
    width: 100%;
    height: 36px;
  }
</style>

<div onclick="googleButtonClick" class="g-signin2">
  <div id="g_id_onload"
    data-client_id="189879899677-k0vif8d0kt6baa0u9gmbbae66oaiphf4.apps.googleusercontent.com"
    data-context="signin"
    data-ux_mode="popup"
    data-callback="onSignIn"
    data-auto_prompt="false">
  </div>

  <div class="g_id_signin"
    data-type="standard"
    data-shape="rectangular"
    data-theme="outline"
    data-text="signin_with"
    data-size="large"
    data-logo_alignment="left">
  </div>
</div>


<script>
  import store from '../store.js'
  import { postGoogleAuth, redirect } from '../request.js'

  export default {
    oncreate() {
      // attach googleButtonClicked variable
      window.isGoogleButtonClicked = false
      window.googleButtonClick = function () {
        window.isGoogleButtonClicked = true
      }

      // attach onSignIn
      window.onSignIn = async (response) => {
        const idToken = response.credential

        try {
          const response = await postGoogleAuth({ idToken })
          const parsedResponse = JSON.parse(response)
          if (parsedResponse.error) {
            return store.set({ serverErrorMessage: parsedResponse.payload.errorMessage })
          }
          redirect()
        } catch (e) {
          console.log(e)
          store.set({ serverErrorMessage: String(e) })
        }
      }
    },
    store: () => store
  }
</script>
