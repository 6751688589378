<style>
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin: 5px 0;
    width: 100%;
  }
  .input-box {
    box-sizing: border-box;
    display: block;
    width: 100%;
    height: 3em;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
  .input-button {
    width: 100%;
    height: 4em;
    background: #149400;
    border: 1px solid #149400;
    border-radius: 4px;
    color: #fff;
    -webkit-appearance: listitem;
    vertical-align: middle;
  }
  .input-button:hover {
    background: #37a426;
  }
  .input-button:active {
    background: #117e00;
  }
  .input-error {
    border: red solid 1px;
  }
  .error-wrapper {
    height: 0.7em;
  }
  .error-text {
    display: block;
    color: red;
    font-size: 0.7em;
  }
  input:focus {
    border: 1px solid #70109d;
    box-shadow: 0 0 3px #70109d;
    -moz-box-shadow: 0 0 3px #70109d;
    -webkit-box-shadow: 0 0 3px #70109d;
    outline-offset: 0px;
    outline: none;
  }
  input .error {
    border: 1px solid red;
  }
  form {
    width: 100%;
  }
</style>

<form on:submit="onSubmit(event)">
  <div class="input-wrapper">
    <input on:blur=onEmailBlur(event.target.value) bind:value=email placeholder="netko@kompare.hr" type="email" class="input-box {emailError ? 'input-error' : ''}"
      autocomplete="email" />
    <div class="error-wrapper">
      <label class="error-text">{emailError}</label>
    </div>
  </div>
  <div class="input-wrapper">
    <input on:blur=onPasswordBlur(event.target.value) bind:value=password placeholder="password" type="password" class="input-box {passwordError ? 'input-error' : ''}"
      autocomplete="current-password" />
    <div class="error-wrapper">
      <label class="error-text">{passwordError}</label>
    </div>
  </div>
  <div class="input-wrapper"><button type="submit" class="input-button">LOGIN</button></div>
</form>

<script>
  import store from '../store.js'
  import { postBasicAuth, redirect } from '../request.js'

  const emailValidation = (email) => {
    if (!email) {
      return { isValid: false, errorMessage: 'e-mail ne smije biti prazan' }
    }

    if (!/@kompare\.hr$/.test(email)) {
      return { isValid: false, errorMessage: 'Unesite ispravan kompare.hr e-mail' }
    }

    return { isValid: true, errorMessage: '' }
  }

  const passwordValidation = (password) => {
    if (!password) {
      return { isValid: false, errorMessage: 'Password ne smije biti prazan' }
    }

    return { isValid: true, errorMessage: '' }
  }

  export default {
    data: () => ({
      loading: false,
      email: '',
      password: '',
      emailError: '',
      passwordError: ''
    }),
    methods: {
      async onSubmit(event) {
        event.preventDefault()
        store.set({ serverErrorMessage: '' })
        const { email, password } = this.get()
        this.onEmailBlur(email)
        this.onPasswordBlur(password)
        const { emailError, passwordError } = this.get()
        if (emailError || passwordError) {
          return
        }

        try {
          const response = await postBasicAuth({ email, password })
          const parsedResponse = JSON.parse(response)
          if (parsedResponse.error) {
            return store.set({ serverErrorMessage: parsedResponse.payload.errorMessage })
          }
          redirect()
        } catch (e) {
          console.log(e)
          store.set({ serverErrorMessage: String(e) })
        }
      },
      onEmailBlur(newValue) {
        const validation = emailValidation(newValue)
        this.set({ emailError: validation.errorMessage })
      },
      onPasswordBlur(newValue) {
        const validation = passwordValidation(newValue)
        this.set({ passwordError: validation.errorMessage })
      }
    },
    store: () => store
  }
</script>