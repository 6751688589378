const BASIC_AUTH_URL = 'auth/basic'
const GOOGLE_AUTH_PATH = 'auth/google'
const DEFAULT_REDIRECT_URL = '/administracija/dashboard/'

export async function redirect() {
  try {
    const params = new URLSearchParams(location.search)
    const redirect = params.get('redirect')
    if (redirect) {
      return window.location.replace(redirect)
    }
    return window.location.replace(DEFAULT_REDIRECT_URL)
  } catch (e) {
    window.location.replace(DEFAULT_REDIRECT_URL)
    console.log(e)
  }
}

export async function postGoogleAuth(params) {
  return postRequest(GOOGLE_AUTH_PATH, params)
}

export async function postBasicAuth(params) {
  return postRequest(BASIC_AUTH_URL, params)
}

async function postRequest(url, params) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url)
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.onload = function () {
      return resolve(xhr.responseText)
    }
    xhr.onerror = function (err) {
      return reject(err)
    }
    xhr.send(prepareArgs(params))

  })
}

function prepareArgs(params) {
  return Object.keys(params).map(elem => `${elem}=${params[elem]}`).join('&')
}
